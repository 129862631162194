import React from 'react';
import styled from 'styled-components';

import { responsiveFont } from 'styles/utils';
import { variables, breakpoints } from 'styles/variables';
import { Container } from 'components/container/Container';
import { Column } from 'components/grid/Column';
import { Row } from 'components/grid/Row';
import { SimpleFooter } from 'components/footer/SimpleFooter';
import { graphql } from 'gatsby';

import AniLink from 'gatsby-plugin-transition-link/AniLink';

import Facebook from 'assets/svg/facebook.svg';
import Instagram from 'assets/svg/instagram.svg';
import Linkedin from 'assets/svg/linkedin.svg';

import Back from 'assets/svg/arrow-left.svg';

import Helmet from 'react-helmet';

import { useTranslation } from 'gatsby-plugin-react-i18next';


// tslint:disable no-default-export

const TermsSLRoot = styled.div`
  background: ${variables.colors.background};
  min-height: 100vh;
`;

const Wrapper = styled.div`
  padding: 0px 0;

  @media (min-width: ${breakpoints.md}) {
    padding: 0px 0;
  }
`;

const SectionTitle = styled.h1`
  ${responsiveFont(38, 44)};

  font-family: ${variables.font.familyHeading};
  font-weight: 900;
  padding-bottom: 20px;
`;

const Links = styled.div`
  ${responsiveFont(14, 18)};
  font-family: ${variables.font.familyHeading};
  font-weight: 400;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const BackIcon = styled(Back)`
  margin-top: 20px;
  transition: 200ms opacity ease-in-out;
  &:hover {
    opacity: 0.5;
  }
`;

const ExternalLink = styled.a`
  ${responsiveFont(14, 18)};
  font-family: ${variables.font.familyHeading};
  font-weight: 400;
  padding-top: 20px;
  color: ${variables.colors.font};
  text-decoration: none;
  transition: 200ms opacity ease-in-out;
  &:hover {
    opacity: 0.5;
  }
`;

export default () => {
  const {t} = useTranslation();

  function createMarkup() {
    return { __html: t('termsBody') };
  }

  return (
    <>
      <Helmet title={t('termsTitle')} />
      <TermsSLRoot>
        <Container>
          <Wrapper>
            <Row>
              <Column md={2} lg={2} sm={12}></Column>
              <Column md={8} lg={8} sm={12}>
                <AniLink cover direction="right" bg="#000" to="/">
                  <BackIcon />
                </AniLink>
                <SectionTitle>{t('termsTitle')}</SectionTitle>
                <div>
                  <div className='prose lg:prose-lg' dangerouslySetInnerHTML={createMarkup()}></div>
                </div>
                <Links>
                  <ExternalLink href="tel:+386 40 377 061">+386 40 377 061</ExternalLink>
                  <br />
                  <ExternalLink href="mailto:hello@trampolin.studio">
                    hello@trampolin.studio
                  </ExternalLink>
                  <SimpleFooter
                    social={[
                      { icon: <Instagram />, to: 'https://www.instagram.com/trampolin.studio' },
                      { icon: <Facebook />, to: 'https://www.facebook.com/Trampolin.studio' },
                      {
                        icon: <Linkedin />,
                        to: 'https://www.linkedin.com/company/trampolin-studio',
                      },
                    ]}
                  ></SimpleFooter>
                </Links>
              </Column>
            </Row>
          </Wrapper>
        </Container>
      </TermsSLRoot>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "contact", "index", "terms","work"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
